import React from 'react';
import { ReactComponent as IconLinkedin } from './assets/icons/Linkedin.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            Aymane's Portfolio
          </div>
          <div className="social">
            <a href="https://www.linkedin.com/in/aymanehilmi/" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconLinkedin className="icon" />
            </a>
            <a href="https://github.com/AymaneHilmi" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Coming Soon</h1>
            <p>follow me on linkedin to see the evolution of my portfolio </p>
          </div>
          <a href="https://www.linkedin.com/in/aymanehilmi/">
            <div className="cta">Follow Me</div>
          </a>
        </div>
        <div className="footer">
          <span><a className="underlined" href="https://www.linkedin.com/in/aymanehilmi/" target="_blank" rel="noopener noreferrer">Aymane Hilmi</a></span>
        </div>
      </div>
    );
  }
}

export default App;